$body-bg: #f8f8f8;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

$headings-font-weight: 300 !default;

$jumbotron-bg: #022559;
$jumbotron-color: #fff;

$navbar-default-bg: #022559;
$navbar-default-color: #fff;
$navbar-default-link-color: #fff;
$navbar-default-link-active-color: #fff;
$navbar-default-link-hover-color: #fff;
$navbar-default-link-active-bg: #337ab7;
$navbar-default-link-hover-bg: #337ab7;
$navbar-default-brand-hover-color: #337ab7;

$component-active-bg: #337ab7;
$component-active-color: #fff;
$dropdown-link-hover-bg: #337ab7;
$dropdown-link-hover-color: #fff;

$nav-tabs-active-link-hover-bg: #fff;

$breadcrumb-bg: transparent;
$breadcrumb-separator: ">";
